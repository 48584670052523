import "./jquery.bxslider.js";

export default function () {

	$('.m-press:not(.js-press-done)').each(function () {
		var wrapper = $(this);

		// slider
		wrapper.find('.js-press').bxSlider({
			speed: 500,
			controls: ($(".js-press-item").length > 1) ? true : false,
			pager: ($(".js-press-item").length > 1) ? true : false,
			adaptiveHeight: true,
			adaptiveHeightSpeed: 250
		});

		// done
		wrapper.addClass('js-press-done');
	});
}

